$color-primary: #fbeedf;
$color-primary-dark: darken($color-primary, 10%);
$color-row: #a1e0bd;
$color-row-dark: darken($color-row, 10%);
$color-row-darker: darken($color-row-dark, 10%);
$color-column: #f9afec;
$color-column-dark: darken($color-column, 10%);
$color-column-darker: darken($color-column-dark, 10%);
$color3: #8384df;
$color-sidebar: #ffd5fa;
$color-target: #b79ff1;
$color-target-light: lighten($color-target, 10%);
$max-cell-size: 192px;
$max-board-size: $max-cell-size * 4 + 16px;
$target-font-size: 24px;
$target-min-size: $target-font-size * 1.5;
$target-padding: $target-font-size / 2;
$target-margin: $target-padding / 2;
$sidebar-max-targets: 3;
$sidebar-max-width: ($target-min-size + $target-padding * 2 + $target-margin) * $sidebar-max-targets;
$mobile-max-width: 960px;


html {
  height: 100%;
  font-family: sans-serif;
  background-color: $color-primary;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
}

input, textarea, button {
  font-family: inherit;
}

.main {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media only screen and (orientation: portrait) {
    align-items: start;
  }
}

.center {
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  max-height: 100%;
  justify-content: center;
  z-index: 1;

  @media only screen and (orientation: portrait) {
    flex-direction: column;
    justify-content: start;
  }
}

.board {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: content-box;
  max-width: $max-board-size;
}

.board-row, .column-products {
  display: flex;
  flex: 1;
  min-height: 0;
}

.cell, .product, .clear-all {
  border-radius: 0;
  border: none;
  flex: 1 1 0;
  aspect-ratio: 1/1;
  font-size: 32px;

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}

.too-high, .incorrect {
  color: #c50000;
}

.clear-all {
}

.row.product, .clear-all {
  margin-left: 16px;
}

.row.product, .row.cell {
  background-color: $color-row;

  &:not(:disabled) {
    &:hover {
      background-color: $color-row-dark;
    }

    &:active {
      background-color: $color-row-darker;
    }
  }
}

.column {
  background-color: $color-column;

  &:not(:disabled) {
    &:hover {
      background-color: $color-column-dark;
    }

    &:active {
      background-color: $color-column-darker;
    }
  }
}

.correct {
  color: gray;
}

.column.product, .clear-all {
  margin-top: 16px;
}

.target {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $target-padding;
  min-width: $target-min-size;
  min-height: $target-min-size;
  border-radius: $target-min-size;
  margin-right: $target-margin;
  margin-top: $target-margin;
  font-size: $target-font-size;
  background-color: $color-target;

  &.achieved {
    background-color: $color-target-light;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  max-width: $sidebar-max-width;
  justify-content: space-between;
  background-color: $color-sidebar;
  padding: 16px;

  @media only screen and (orientation: portrait) {
    box-sizing: border-box;
    flex-direction: row;
    margin-left: 0;
    margin-top: 16px;
    max-width: none;
    width: 100%;
  }
}

.targets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (orientation: portrait) {
    margin-right: 16px;
  }
}

.control {
  width: 100%;
  font-size: 32px;

  @media only screen and (orientation: portrait) {
    min-width: 128px;
    height: 100%;
  }
}

.build-info {
  position: fixed;
  right: 0;
  top: 0;
  text-align: right;
  padding: 1em;
  text-decoration: none;
  color: $color-primary-dark;
  z-index: 0;

  &:hover {
    background-color: $color-primary-dark;
    color: black;
  }

  @media only screen and (orientation: portrait) {
    top: auto;
    bottom: 0;
  }
}