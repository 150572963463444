html {
  height: 100%;
  box-sizing: border-box;
  background-color: #fbeedf;
  font-family: sans-serif;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

input, textarea, button {
  font-family: inherit;
}

.main {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (orientation: portrait) {
  .main {
    align-items: start;
  }
}

.center {
  max-height: 100%;
  z-index: 1;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  display: flex;
}

@media only screen and (orientation: portrait) {
  .center {
    flex-direction: column;
    justify-content: start;
  }
}

.board {
  box-sizing: content-box;
  max-width: 784px;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.board-row, .column-products {
  min-height: 0;
  flex: 1;
  display: flex;
}

.cell, .product, .clear-all {
  aspect-ratio: 1 / 1;
  border: none;
  border-radius: 0;
  flex: 1;
  font-size: 32px;
}

.cell:disabled, .product:disabled, .clear-all:disabled {
  cursor: not-allowed;
}

.cell:not(:disabled), .product:not(:disabled), .clear-all:not(:disabled) {
  cursor: pointer;
}

.too-high, .incorrect {
  color: #c50000;
}

.row.product, .clear-all {
  margin-left: 16px;
}

.row.product, .row.cell {
  background-color: #a1e0bd;
}

.row.product:not(:disabled):hover, .row.cell:not(:disabled):hover {
  background-color: #7bd3a2;
}

.row.product:not(:disabled):active, .row.cell:not(:disabled):active {
  background-color: #54c787;
}

.column {
  background-color: #f9afec;
}

.column:not(:disabled):hover {
  background-color: #f580e1;
}

.column:not(:disabled):active {
  background-color: #f250d5;
}

.correct {
  color: gray;
}

.column.product, .clear-all {
  margin-top: 16px;
}

.target {
  min-width: 36px;
  min-height: 36px;
  background-color: #b79ff1;
  border-radius: 36px;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  margin-right: 6px;
  padding: 12px;
  font-size: 24px;
  display: flex;
}

.target.achieved {
  background-color: #d8ccf7;
}

.sidebar {
  max-width: 198px;
  background-color: #ffd5fa;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
  padding: 16px;
  display: flex;
}

@media only screen and (orientation: portrait) {
  .sidebar {
    box-sizing: border-box;
    max-width: none;
    width: 100%;
    flex-direction: row;
    margin-top: 16px;
    margin-left: 0;
  }
}

.targets {
  flex-flow: wrap;
  display: flex;
}

@media only screen and (orientation: portrait) {
  .targets {
    margin-right: 16px;
  }
}

.control {
  width: 100%;
  font-size: 32px;
}

@media only screen and (orientation: portrait) {
  .control {
    min-width: 128px;
    height: 100%;
  }
}

.build-info {
  text-align: right;
  color: #f5d6b2;
  z-index: 0;
  padding: 1em;
  text-decoration: none;
  position: fixed;
  top: 0;
  right: 0;
}

.build-info:hover {
  color: #000;
  background-color: #f5d6b2;
}

@media only screen and (orientation: portrait) {
  .build-info {
    top: auto;
    bottom: 0;
  }
}

/*# sourceMappingURL=index.121780c8.css.map */
